import './styles.scss';
import './Home.scss';
import Content from './componets/Content.js';
import ContentBinlist from './componets/ContentBinlist.js';
import Header from './componets/Header.js';
import React, { useState } from 'react';


function Home({LogOut, LogPage}) {

  const [activeTab, setActiveTab] = useState('converter');
  return(
    <div className="Home">
        < Header LogOut = {LogOut} switchTab={setActiveTab}/>
        {activeTab === 'converter' ? <Content LogPage={LogPage} /> : <ContentBinlist LogPage={LogPage} />}

    </div>
  );
}




export default Home;
