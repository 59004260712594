import './Header.scss';
import {BiLogOut, BiCreditCard, BiLogoBitcoin} from "react-icons/bi";
import React, { useState } from 'react';
// import React, { useState } from 'react';

function Header({LogOut, switchTab}) {
    
    const handleOut = () => {
        LogOut();
    }
    return(
        <header>
            <div className="left d-flex f-row ml-50">
                <div className="tab mr-30">
                    <a href="#" onClick={() => switchTab('converter')}>Converter</a>
                </div>
                <div className="tab mr-30" >
                    <a href="#"  onClick={() => switchTab('binlist')}>Binlist</a>
                </div>
            </div>
            <div>
            </div>
            <div className="log">
                <BiLogOut 
                onClick={handleOut}
                className='mr-10 logIcon'/>
            </div>
        </header>
    );
}

export default Header;