import './Log.scss';
import { BiUser, BiSolidLock } from "react-icons/bi";
import React, { useState } from 'react';

function Log({ onLogin }) {
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        onLogin(password, login);
    };

    return (
        <div className="boxLoginPage">
            <div className="box-log">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="log-panel">
                        <div className="log-container">
                            <BiUser  className='log-icon'/>
                            <input
                                className='Input'
                                type="text"
                                defaultValue={login}
                                onChange={(e) => setLogin(e.target.value)}
                                placeholder='Login'
                            />
                        </div>
                        <div className="log-container">
                            <BiSolidLock className='log-icon'/>
                            <input
                                className='Input'
                                type="password"
                                defaultValue={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='Password'
                            />
                        </div>
                        <div className="button-panel">
                            <button type='submit' className="log-button">
                                Log In
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Log;
