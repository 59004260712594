import './Content.scss';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

function ContentBinlist({LogPage}) {
    const date1Refs = useRef([]);
    const date2Refs = useRef([]);
    const valueRefs = useRef([]);
    const linkRefs = useRef([]);

    const [tableData, setTableData] = useState([
        [{ value: '' }],
        [{ value: '' }]
    ]);


    const [alertOpen, setAlertOpen] = useState(false)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setAlertOpen(false);
      };



    
    const formatReceivedData = (data) => {
        const maxRow = Math.max(...data.map(item => item.rowIndex));
        const maxCol = Math.max(...data.map(item => item.colIndex));
    
        const formattedData = Array.from({ length: maxRow + 1 }, () => 
            Array.from({ length: maxCol + 1 }, () => ({ link: '',value: '', date1: '', date2: '' }))
        );
    
        data.forEach(item => {
            formattedData[item.rowIndex][item.colIndex].link = item.link;
            formattedData[item.rowIndex][item.colIndex].value = item.value;
            formattedData[item.rowIndex][item.colIndex].date1 = item.date1;
            formattedData[item.rowIndex][item.colIndex].date2 = item.date2;
        });
    
        return formattedData;
    };
    

    useEffect(() => {
      
            const token = localStorage.getItem('token');
            console.log(token);
            
            // axios.get(`http://127.0.0.1:8080/get_table_data_binlist/?token=${token}`)
            axios.get(`/api_ad/get_table_data_binlist/?token=${token}`)
                .then(response => {
                    if(response.data === 'ok'){
                        console.log("No data found 1234567");
                        LogPage();
                    } 
                    else if(response.data.length === 0){
                        console.log("Empty table");
                    }
                    else {
                        const formattedData = formatReceivedData(response.data);
                        setTableData(formattedData);
                        // console.log(response);
                        // console.log(response.data);
                        console.log(formattedData);
                        console.log("Data loaded");
                    }
                });

    }, []);

 
    const handleCellChange = (rowIndex, colIndex, key, value) => {
        // Создаем копию массива
        const updatedData = tableData.map(row => row.map(cell => ({ ...cell })));
    
        if (!updatedData[rowIndex][colIndex]) {
            updatedData[rowIndex][colIndex] = {};
        }
        updatedData[rowIndex][colIndex][key] = value;
    
        if (colIndex === updatedData[0].length - 1 && value !== '') {
            updatedData.forEach(row => row.push({ link: '', value: '', date1: '', date2: '' }));
        }
    
        if (rowIndex === updatedData.length - 1 && value !== '') {
            // Создаем новую строку с новыми объектами
            updatedData.push(Array.from({ length: updatedData[0].length }, () => ({link: '', value: '', date1: '', date2: '' })));
        }
    
        setTableData(updatedData);
    }
    

    const sendDataToServer = async (data) => {
        try {
            console.log(data);
            const token = localStorage.getItem('token');
            // const response = await axios.post(`http://127.0.0.1:8080/collect_data_binlist/?token=${token}`, data);
            const response = await axios.post(`/api_ad/collect_data_binlist/?token=${token}`, data);
            if(response.data === 'ok'){
                console.log("No data found 1234567");
                LogPage();
            } 
            console.log("Data sent successfully:", response.data);
            setAlertOpen(true);

        } catch (error) {
            console.error("There was an error sending the data:", error);
        }
    }

    const collectTableData = () => {
        const currentDate = new Date().toISOString().split('T')[0];
        const collectedData = tableData.flatMap((row, rowIndex) => 
            row.map((cell, colIndex) => ({
                rowIndex,
                colIndex,
                date1: date1Refs.current[`${rowIndex}-${colIndex}`]?.value || currentDate,
                date2: date2Refs.current[`${rowIndex}-${colIndex}`]?.value || currentDate,
                value: cell.value || '',
                link: cell.link || ''
            }))
        ).filter(item => item.value);

        sendDataToServer({ "array": collectedData });
    }

    const handleClearData = () => {
            const token = localStorage.getItem('token');
            // axios.post(`http://127.0.0.1:8080/clear_data_binlist/?token=${token}`)
            axios.post(`/api_ad/clear_data_binlist/?token=${token}`)
                .then(response => {
                    if(response.data === 'ok'){
                        console.log("No data found 1234567");
                        LogPage();
                    } 
                    else{
                        setTableData([
                            [{ value: '' }],
                            [{ value: '' }]
                        ]);
                    }
                    window.location.reload();
                })
                .catch(error => {
                    console.error("Error clearing data:", error);
                });

    };

    return (
        <div className="Content">
            <div className="mainContent">
                <table>
                    <tbody>
                        {tableData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, colIndex) => (
                                    <td key={colIndex}>
                                        <div className="box">
                                            <div className="topBox">
                                                <input 
                                                    type="date" 
                                                    value={cell.date1 || ''}
                                                    ref={el => date1Refs.current[`${rowIndex}-${colIndex}`] = el} 
                                                    onChange={e => handleCellChange(rowIndex, colIndex, 'date1', e.target.value)}
                                                />
                                                <input 
                                                    type="date" 
                                                    value={cell.date2 || ''}
                                                    ref={el => date2Refs.current[`${rowIndex}-${colIndex}`] = el} 
                                                    onChange={e => handleCellChange(rowIndex, colIndex, 'date2', e.target.value)}
                                                />

                                            </div>
                                            <div className="downBox">
                                                <input 
                                                    value={cell.value || ''}
                                                    onChange={e => handleCellChange(rowIndex, colIndex, 'value', e.target.value)}
                                                    ref={el => valueRefs.current[`${rowIndex}-${colIndex}`] = el}
                                                />
                                                <input 
                                                    value={cell.link || ''}
                                                    onChange={e => handleCellChange(rowIndex, colIndex, 'link', e.target.value)}
                                                    ref={el => linkRefs.current[`${rowIndex}-${colIndex}`] = el}
                                                />
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="button-box d-flex f-row justify-center">
                <button onClick={collectTableData} className='mr-20'>Save</button>
                <button onClick={handleClearData}>Clear</button>
                <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    This is a success alert — check it out!
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}

export default ContentBinlist;
