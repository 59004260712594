import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Log from "./Log.js";
import Home from "./Home.js";

import React, { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

// ... (остальные импорты и код)

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/login');
}, []);
  const handleLogin = async (password, login) => {
    try {
        // const response = await axios.get(`http://127.0.0.1:8080/api/login/?login=${login}&pass=${password}`);
        const response = await axios.get(`/api/login/?login=${login}&pass=${password}`);
        console.log(response);
        if (response.data.ok === true) {
            setIsAuthenticated(true);
            navigate("/content");
            localStorage.setItem('token', response.data.token);
        } else {
            alert("Wrong password!");
        }
    } catch (error) {
        console.error("Error during the authentication process:", error);
        alert("An error occurred during the authentication process.");
    }
  }  // Эта закрывающая скобка отсутствовала.

  const handleLogout = async () => {
    console.log(localStorage.getItem("token"));
    console.log({"token": localStorage.getItem('token')  });
    // const resToken = await axios.post('http://127.0.0.1:8080/api/logout/', { "token": localStorage.getItem('token') })
    const resToken = await axios.post('/api/logout/', { "token": localStorage.getItem('token') })
    console.log(resToken);

      // Удаление токена из localStorage
      localStorage.removeItem('token');
      setIsAuthenticated(false);
      // Переход на страницу входа
      navigate('/login');
  }

  const LogPage = () => {
    navigate('/login');
  }
  
  
  return (
    <Routes>
      <Route path="/login" element={<Log onLogin={handleLogin} />} />
      <Route path="/content" element={<Home LogOut={handleLogout} LogPage={LogPage}/>} />
    </Routes>
  );
}

export default App;
